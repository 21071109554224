<template>
  <div :class="classes">
    <template v-if="!display || hasWeekdays">
      <div v-for="weekday in orderedWeekdays" :key="weekday.code">
        <v-card
          class="caption"
          :title="weekday.long"
          :elevation="value[weekday.key] || 0"
          :color="value[weekday.key] ? 'secondary' : 'grey lighten-4'"
          :dark="!!value[weekday.key]"
          :ripple="false"
          :tile="small"
          @click="$emit( 'click:item', weekday )"
        >
          <div>{{ small ? weekday.code : weekday.short }}</div>
        </v-card>
      </div>
    </template>
  </div>
</template>

<script>
import { capitalize } from '@/utils';

export const WEEKDAYS = {
  sunday: 0,
  monday: 0,
  tuesday: 0,
  wednesday: 0,
  thursday: 0,
  friday: 0,
  saturday: 0,
};

export const WEEKDAY_CODES = {
  sunday: 'D',
  monday: 'L',
  tuesday: 'M',
  wednesday: 'X',
  thursday: 'J',
  friday: 'V',
  saturday: 'S',
};

export function setDay( date, day ) {
  const current = date.getDay();
  const distance = day > 6 ? ( day + 7 - current ) % 7 : day - current;
  date.setDate( date.getDate() + distance );
  return date;
}

export function getDayName( date, mode, locale = 'en-US' ) {
  return capitalize( date.toLocaleDateString( locale, { weekday: mode }));
}

export const getWeekdays = lang => Object.keys( WEEKDAYS ).map(( key, value ) => {
  const long = getDayName( setDay( new Date(), value ), 'long', lang );
  const short = getDayName( setDay( new Date(), value ), 'short', lang );
  return { key, value, long, short, code: WEEKDAY_CODES[key] };
});

export default {
  props: {
    value: {
      type: Object,
      default: () => ({})
    },
    startDay: {
      type: [ String, Number ],
      default: 1
    },
    locale: {
      type: String,
      default: 'es'
    },
    small: Boolean,
    display: Boolean
  },
  computed: {
    classes() {
      return {
        weekdays: true,
        'weekdays--small': this.small
      }
    },
    weekdays() {
      return getWeekdays( this.locale );
    },
    hasWeekdays() {
      return Object.keys( WEEKDAYS ).some( v => this.value[v] );
    },
    orderedWeekdays() {
      const start = Number( this.startDay ) || 0;
      return this.weekdays.map(( _, i ) => {
        return this.weekdays[( start + i ) % 7 ];
      });
    },
  }
}
</script>

<style lang="scss">
.weekdays {

  display: flex;
  flex-wrap: nowrap;
  justify-content: space-around;

  > div {
    $width: 100% * 1/9;
    flex: 0 0 $width;
    max-width: $width;
    padding: 8px 0;
  }

  .v-card {

    position: relative;
    padding-top: 100%;

    > div {
      position: absolute;
      top: 0; left: 0; right: 0; bottom: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &.weekdays--small {
    .v-card {
      font-size: 10px !important;
    }
  }
}
</style>
