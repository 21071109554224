<template>
  <v-form
    ref="form"
    class="pa-2"
    @submit.prevent="onSave"
  >
    <v-row class="my-0">
      <v-col class="py-0" cols="12" sm="4">

        <image-field
          v-model="model.picture"
          :rules="rules.picture"
          class="mb-4"
          height="152"
          clearable
          contain
        />

        <btn
          v-if="!isNew"
          class="mb-3"
          color="primary"
          icon-left="mdi-eye-outline"
          :to="`/booking?offer=${model.id}`"
          block
        >
          {{ $t('offerMatch.title') }} ({{ model.matchingsCount || 0 }})
        </btn>

        <api-field
          v-model="model.customer"
          :label="$t('inputs.customer.label')"
          :rules="rules.customer"
          api="customer"
          item-search="name"
          clearable
        />

        <number-field
          v-model="model.people"
          :label="$t('inputs.people.label')"
          :rules="rules.people"
          min="0"
          int
        />

        <number-field
          v-model="model.priority"
          :label="$t('inputs.priority.label')"
          :rules="rules.priority"
          int
        />

      </v-col>
      <v-col class="py-0" cols="12" sm="8">

        <select-field
          v-model="model.status"
          :label="$t('inputs.status.label')"
          :items="statusItems"
        />

        <time-table-field
          v-model="model.offerTimetable"
        />

      </v-col>
      <v-col class="py-0" cols="12">

        <textarea-field
          v-model="model.conditions"
          :label="$t('inputs.conditions.label')"
          :rules="rules.conditions"
          min-height="100%"
        />

      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import TextareaField from '@/components/form/TextareaField';
import NumberField from '@/components/form/NumberField';
import SelectField from '@/components/form/SelectField';
import ApiField from '@/components/form/ApiField';
import ImageField from '@/components/form/ImageField';
import TimeTableField from '@/components/form/TimeTableField';
import { required } from '@/utils/rules';

export default {
  components: {
    TextareaField,
    NumberField,
    SelectField,
    ImageField,
    TimeTableField,
    ApiField
  },
  props: {
    value: Object,
    isNew: Boolean,
    statusItems: Array,
    save: Function
  },
  data() {
    return {
      model: this.value || {}
    }
  },
  computed: {
    rules() {
      return {
        customer: [
          required( this.$t('inputs.customer.rules.required'))
        ],
        conditions: [
          required( this.$t('inputs.conditions.rules.required'))
        ],
        /*people: [
          required( this.$t('inputs.people.rules.required'))
        ]*/
      };
    }
  },
  watch: {
    value( model ) {
      this.model = model || {};
    }
  },
  methods: {
    onSave() {
      this.$emit( 'input', this.model );
      this.save && this.$nextTick( this.save );
    },
    validate() {
      return this.$refs.form && this.$refs.form.validate();
    },
    resetValidation() {
      return this.$refs.form && this.$refs.form.resetValidation();
    }
  }
}
</script>
