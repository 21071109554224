<template>
  <div
    class="time-table-field"
    :class="{'time-table-field--error': error.length }"
  >

    <date-field
      v-model="startDate"
      :label="$t('inputs.startDate.label')"
      :rules="rules.startDate"
      :max="endDate"
      placeholder="dd/mm/yyyy"
      clearable
    />

    <date-field
      v-model="endDate"
      :label="$t('inputs.endDate.label')"
      :rules="rules.endDate"
      :min="startDate"
      placeholder="dd/mm/yyyy"
      clearable
    />

    <time-field
      v-model="startOffset"
      :label="$t('inputs.startOffset.label')"
      :rules="rules.startOffset"
      placeholder="HH:MM"
      returnDate
      clearable
    />

    <time-field
      v-model="endOffset"
      :label="$t('inputs.endOffset.label')"
      :rules="rules.endOffset"
      placeholder="HH:MM"
      returnDate
      clearable
    />

    <label class="caption">
      {{ $t('inputs.weekdays.label') }}
    </label>

    <weekdays
      :value="model"
      :start-day="startDay"
      :locale="locale"
      @click:item="toggleWeekday"
    />

    <v-messages
      :value="error"
      color="error"
    />

  </div>
</template>

<script>
import DateField from '@/components/form/DateField';
import TimeField from '@/components/form/TimeField';
import Weekdays, { WEEKDAYS } from '@/components/values/Weekdays';
import { required, beforeDate, afterDate } from '@/utils/rules';
import { mapState } from 'vuex';

export const DEFAULT_VALUES = {
  id: -1,
  startDate: undefined,
  startOffset: undefined,
  endDate: undefined,
  endOffset: undefined,
  ...WEEKDAYS
};

export default {
  components: { DateField, TimeField, Weekdays },
  props: {
    value: {
      type: Object,
      default: () => ({})
    },
    startDay: {
      type: [ String, Number ],
      default: 1
    },
    locale: {
      type: String,
      default: 'es'
    }
  },
  inject: {
    form: { default: null }
  },
  data: () => ({
    ...DEFAULT_VALUES,
    isMounted: false,
    isResetting: false,
    valid: true,
    shouldValidate: true,
    error: []
  }),
  computed: {
    ...mapState( 'app', [ 'lang' ]),
    model() {
      return Object.keys( DEFAULT_VALUES ).reduce(( obj, key ) => {
        switch (key) {
          case "endDate":
            obj[key] = this[key] || null;
            break;
          case 'startOffset':
          case 'endOffset':
            if ( this[key] instanceof Date ) {
              obj[key] = this[key].getTime();
            } else {
              obj[key] = this[key];
            }
            break;
          default:
            obj[key] = this[key];
        }
        return obj;
      },{});
    },
    rules() {
      return {
        startDate: [
          required( this.$t('inputs.startDate.rules.required')),
          beforeDate(
            this.endDate,
            'DD/MM/YYYY',
            this.$t('inputs.startDate.rules.beforeDate')
          )
        ],
        endDate: [
          afterDate(
            this.startDate,
            'DD/MM/YYYY',
            this.$t('inputs.endDate.rules.afterDate')
          )
        ],
        startOffset: [
          required( this.$t('inputs.startOffset.rules.required'))
        ],
        endOffset: [
          required( this.$t('inputs.endOffset.rules.required'))
        ]
      }
    },
    hasError() {
      return !!this.error.length;
    }
  },
  watch: {
    value: 'refresh',
    model( value ) {
      this.$emit( 'input', value );
    },
    ...( Object.keys( WEEKDAYS ).reduce(( obj, key ) => {
      obj[key] = 'validate';
      return obj;
    },{}))
  },
  methods: {
    refresh( value ) {
      Object.keys( DEFAULT_VALUES ).forEach( key => {
        this[key] = value[key];
      });
    },
    toggleWeekday( weekday ) {
      this[weekday.key] = this[weekday.key] ? 0 : 1;
    },
    validate() {
      /*if ( ! this.isMounted || this.isResetting ) return this.valid;
      if ( Object.keys( WEEKDAYS ).some( key => this[key] )) {
        this.error = [];
        this.valid = true;
      } else {
        this.error = [this.$t('inputs.weekdays.rules.required')];
        this.valid = false;
      }*/
      return this.valid;
    },
    reset() {
      this.resetValidation();
      Object.keys( DEFAULT_VALUES )
        .forEach( key => this[key] = DEFAULT_VALUES[key] );
    },
    resetValidation() {
      this.error = [];
      this.isResetting = true;
      this.$nextTick(() => this.isResetting = false );
    }
  },
  created() {
    this.form && this.form.register(this);
  },
  beforeDestroy() {
    this.form && this.form.unregister(this);
  },
  beforeMount() {
    this.refresh( this.value );
  },
  mounted() {
    this.isMounted = true;
  }
}
</script>

<style>
.time-table-field--error .weekdays .v-card {
  border: 1px solid var(--v-error-base) !important;
}
</style>
